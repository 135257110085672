
	.table-basic {
		@apply w-full shadow-none rounded-t-[.25rem] border border-neutral-30 flex !border-b-0 overflow-x-auto;

		table {
			@apply w-full;
		}

		thead {
			@apply w-full;

			&.with-border {
				tr {
					th {
						@apply border border-solid border-neutral-30;
					}
				}
			}

			tr {
				th {
					@apply px-8 pr-32 py-10 
					text-sm text-left font-semibold leading-100 text-neutral-80 bg-white 
					!border-b !border-b-neutral-30 border-l-0 border-r-0
					cursor-pointer  hover:bg-neutral-30 relative;

					&:after {
						@apply opacity-0 transition-opacity duration-200 absolute right-4 top-0 bottom-0 my-auto w-24 h-24 block;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC41Ij4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgcng9IjExLjUiIGZpbGw9IiNGOEY5RkEiLz4KPHBhdGggZD0iTTEyLjAwMDIgNi4xNjY2NlYxNy44MzMzIiBzdHJva2U9IiM0OTUwNTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcuODMyNTIgMTAuMzMzM0wxMiA2LjE2NTgzTDE2LjE2NzUgMTAuMzMzMyIgc3Ryb2tlPSIjNDk1MDU3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHJ4PSIxMS41IiBzdHJva2U9IiNFOUVDRUYiLz4KPC9nPgo8L3N2Zz4K);
					}
					&.up {
						@apply bg-neutral-10;
						&:after {
							@apply opacity-100 border border-neutral-30 rounded-full;
						}
					}
					&.down {
						@apply bg-neutral-10;

						&:after {
							@apply opacity-100 border border-neutral-30 rounded-full;
							background-size: contain;
							background-repeat: no-repeat;
							background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMjMuNSIgeT0iMjMuNSIgd2lkdGg9IjIzIiBoZWlnaHQ9IjIzIiByeD0iMTEuNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCAyMy41IDIzLjUpIiBmaWxsPSIjRjhGOUZBIi8+CjxwYXRoIGQ9Ik0xMS45OTk4IDE3LjgzMzNMMTEuOTk5OCA2LjE2NjY4IiBzdHJva2U9IiM0OTUwNTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE2LjE2NzUgMTMuNjY2N0wxMiAxNy44MzQyTDcuODMyNDggMTMuNjY2NyIgc3Ryb2tlPSIjNDk1MDU3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxyZWN0IHg9IjIzLjUiIHk9IjIzLjUiIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgcng9IjExLjUiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMjMuNSAyMy41KSIgc3Ryb2tlPSIjRTlFQ0VGIi8+Cjwvc3ZnPgo=);
						}
					}
					&.number {
						@apply !text-right pr-8 pl-24;
						&:after {
							@apply left-4;
						}
					}

					&:hover {
						&:after {
							@apply opacity-100;
						}
					}
				}
			}
		}
		tbody {
			@apply bg-white;
			.td-container {
				@apply text-sm font-normal text-neutral-80 leading-[120%] h-56 flex items-center w-max max-w-[25rem];
			}
			tr {
				@apply hover:bg-neutral-20;

				td {
					@apply border border-neutral-30;
				}
				.red {
					@apply !text-[#EC3539];
				}
				.green {
					@apply !text-[#1CC066];
				}
				.number {
					@apply !text-right;

					* {
						@apply !text-right;
					}
				}
			}
		}

		tbody.odd {
			tr {
				&:nth-child(odd) {
					@apply bg-neutral-10;
				}
			}
		}
	}

	.table-pagination {
		.rounded-pag {
			@apply rounded-full w-32 h-32 bg-white border border-neutral-30 grid place-items-center hover:bg-primary-pure hover:text-white text-primary-pure cursor-pointer;
		}
		.current {
			@apply font-medium text-neutral-75 text-sm;
		}
	}

