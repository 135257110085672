
	.tabs {
	}
	.tabs-wrapper {
		@apply md2:overflow-x-auto md2:snap-mandatory md2:snap-x;
	}
	.tab-item {
		@apply p-20 tracking-tight !h-[48px] font-normal text-base inline-flex gap-8 text-neutral-75 cursor-pointer border-b-2 border-transparent transition bg-transparent hover:bg-neutral-20 items-center shrink-0  md2:snap-start select-none;

		&.active {
			@apply bg-white text-primary-pure border-primary-pure;
			--icon-color: #2b5b83;
		}
		svg {
			@apply h-20 w-20;
		}
	}

	.tab-collapse {
		@apply  !h-auto !opacity-100 !static;
		transition: height 0.3s ease;
		&.active {
			@apply !h-auto !opacity-100 !static;
		}
	}

