body {
  overflow-x: hidden !important;
  /* opacity: 0; */
  font-feature-settings: 'pnum' on, 'lnum' on;
  /* @apply bg-[#ffffff]!; */


  .is-loading {
    @apply !relative !text-transparent !pointer-events-none;
    >* {
      @apply !text-transparent;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      margin: auto;
      width: 20px;
      height: 20px;
      border: 5px solid;
      border-color: white transparent;
      border-radius: 50%;
      display: inline-block;
      animation: rotation 1s linear infinite;
    }
  }

  $size: 6px;
  $foreground-color: rgb(219, 219, 219);
  $background-color: #f5f5f5;

  --width-menu: 21.5rem;
  --width-menu-closed: 5.625rem;
  --width-menu-1: 21.5rem;
  --width-menu-2: 21.5rem;
  --width-menu-3: 21.5rem;

  .hover-with-svg:hover {
    --icon-color: #ffffff !important;
  }

  .listra {
    background-color: #2b5b831a;
    position: relative;
    background-image: linear-gradient(135deg, transparent 14px, #2b5b83 14px, transparent 15px);
    background-size: 20px 20px;
  }

  .hover-with-svg:hover > svg {
    --icon-color: #ffffff !important;
  }

  .container-main {
    min-height: 100vh;
    width: 100%;
    padding-left: var(--width-menu-closed);
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  * {
    &::-webkit-scrollbar {
      width: $size;
      height: $size;
    }

    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
      background: $background-color;
    }
  }

  &.DOMContentLoaded {
    opacity: 1;
  }

  &.DOMContentLoaded .container-login {
    @apply translate-y-0 opacity-100;
  }
}

html,
body {
  scroll-behavior: smooth !important;
}
