@tailwind base;
@tailwind components;
@import "./assets/components/Grid.css";
@import "./assets/components/GridRelatorio.css";
@import "./assets/components/Html.css";
@import "./assets/components/Input-check.css";
@import "./assets/components/Input-check-label.css";
@import "./assets/components/Input-text.css";
@import "./assets/components/Input-toggle.css";
@import "./assets/components/Label.css";
@import "./assets/components/Select.css";
@import "./assets/components/Select-Multiple.css";
@import "./assets/components/Show-password.css";
@import "./assets/components/Side-menu.css";
@import "./assets/components/Sucesso-screen.css";
@import "./assets/components/TableBasic.css";
@import "./assets/components/TableFluxo.css";
@import "./assets/components/Tabs.css";
@import "./assets/components/Textarea.css";
@import "./assets/components/TextHelper.css";
@import "./assets/components/Texto.css";
@import "./assets/components/Accordion.css";
@import "./assets/components/Avatar.css";
@import "./assets/components/Badge.css";
@import "./assets/components/Body.css";
@import "./assets/components/Breadcrumb.css";
@import "./assets/components/Button.css";
@import "./assets/components/Card.css";
@import "./assets/components/Container.css";
@import "./assets/components/DataTable.css";
@import "./assets/components/Dropdown.css";
@import "./assets/components/ErrorList.css";
@import "./assets/components/Flatpicker.css";

@tailwind utilities;

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

html,
body {
  overscroll-behavior-y: none !important;
}

.cameraContainer {
  background-color: white !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 999 !important;
  width: 100% !important;
  height: 100% !important;
}
.input-text {
  @apply h-40 leading-150;
  background-color: transparent;
  color: #686e74;
  font-weight: 400;
  font-size: 1rem;
  padding: 14px;
  border: 1px solid #efefef;
  border-radius: 3px;
  position: relative;
  transition: color;
  transition-duration: 200ms;
  height: 2.5rem;
  line-height: 150%;

  /* ~ svg {
    @apply text-neutral-70;
  }

  &.inverse {
    @apply bg-white;
  }

  &:hover {
    @apply border-neutral-50;
  }

  &.active {
    @apply border-neutral-60;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 0 transparent inset !important ;
    font-size: 1rem;


    -webkit-text-fill-color: #343A40;
    font-family: "Be Vietnam Pro", sans-serif !important;
  } */
}

.div-check-secure {
  align-items: center;
  place-content: center;
  border: 1px solid #efefef;
  margin-top: 1rem;
  padding-bottom: 1rem;
  max-height: 3rem;
}

.input-check-label {
  @apply h-40 leading-150;
  background-color: transparent;
  color: #686e74;
  font-weight: 400;
  font-size: 1rem;
  padding: 14px;
  position: relative;
  transition: color;
  transition-duration: 200ms;
  height: 2.5rem;
  line-height: 150%;
}

input.input-check-label {
  margin-top: 0.7rem;
  width: 1rem;
}

.headline-3 {
  @apply !text-xs !font-medium !leading-[24px] !tracking-tight;
}


.dialog-adversitement  .p-dialog-header{

  border-bottom: 1px solid  !important;
  
  @apply  !border-neutral-30;
}

.dialog-adversitement  .p-dialog-header .p-dialog-title{
  background: #E92C2C1A!important;
  text-align: center;
  font-weight: 500!important;
  font-size: 16px!important;
  color: #E92C2C;
}