
	.password {
		@apply absolute right-12 w-40 h-40 top-0 bottom-0 my-auto z-10 grid bg-transparent transition duration-[3000ms] cursor-pointer rounded-lg;

		&.hover {
			@apply text-neutral-30;
		}

        ion-icon{
            @apply text-2xl m-auto text-neutral-60;
    
        }
	}

	.eye-off {
		@apply hidden;
	}

	.mostra .eye {
		@apply hidden;
	}

	.mostra .eye-off {
		@apply block;
	}
