
   .grid-columns-geral {
      @apply grid border border-t-0 border-r-0 border-neutral-30 divide-neutral-30 divide-x md2:min-w-[1250px];

   }

   .grid-rows-two {
      @apply grid grid-rows-[144px_1fr] divide-y divide-neutral-30;
   }

   .item_compra_header {
      @apply flex justify-center items-center px-8 py-16;
   }

   .item_compra {
      @apply flex flex-col items-center justify-center text-sm text-neutral-80 font-medium flex-wrap !border-b border-neutral-30;
   }

   .grid-rows-fornecedor {
      @apply grid grid-rows-[144px_1fr_1fr];

   }

   .container-info {

      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #212529;
      font-weight: 600;

      p {
         @apply text-neutral-100 font-semibold opacity-[0.7];
      }

   }

   .orcamento {
      @apply bg-neutral-10 text-sm text-neutral-100 font-semibold px-4 py-16 inline-flex items-center;
   }

   .expecificacao {
      height: max-content;
      padding: 6px;
     word-wrap:break-word;

width: 17.5rem;
   }
   .valor{
      display:flex;
      align-items:center;
      padding: 6px;

   }
   .qnt{
      display:flex;
      align-items:center;
      padding: 6px;
   }
