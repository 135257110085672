
	.breadcrumb {
		.breadcrumb-wrapper {
			li {
				@apply shrink-0 md2:hidden;
				&:first-child {
					@apply md2:inline-flex;
				}
				&:last-child {
					@apply md2:inline-flex;
				}
				* {
					@apply text-neutral-75 text-sm font-medium inline-flex items-center leading-100;
				}
				&.active {
					* {
						@apply text-primary-pure;
					}
				}
			}
			li + li {
				position: relative;
				padding-left: 1.25rem;

				&:after {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto 0;
					width: 1.25rem;
					height: 1.25rem;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDE2TDE0IDEyTDEwIDgiIHN0cm9rZT0iIzg2OEU5NiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}
			}
		}
	}
	.breadcrumb-mobile {
		@apply hidden md2:inline-flex my-32;
	}

