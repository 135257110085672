

    .ms-container {
        width: 100%;
	
    }

    .ms-elem-selectable,
    .ms-elem-selection {
        padding: .5rem !important;
        transition: .3se ease;
        font-size: .875rem !important;
        color: rgba(0, 0, 0, 0.6) !important;
        font-weight: 400;
    }

    .ms-container .ms-selectable li.ms-hover,
    .ms-container .ms-selection li.ms-hover {
        background-color: #004993;
        color: #fff !important;

    }

    .ms-selectable,
    .ms-selection {
        border: 0 !important;
    }

    .ms-list {
        border-radius: 4px !important;
        border: 1px solid #E6E6E6 !important;
        box-shadow: none !important;
    }


    @media(max-width: 639px) {


        .ms-selectable {
            float: initial !important;
            width: 100% !important;
        }

        .ms-selection {
            margin-top: 2rem;
            float: initial !important;
            width: 100% !important;
        }

    }

