
	.errorlist {
		@apply flex flex-wrap gap-24 mx-16 my-0;

		li {
			@apply uppercase text-neutral-80 font-bold;

			ul {
				&.errorlist {
					@apply m-0 gap-0;
				}
				li {
					@apply font-medium !normal-case text-neutral-70;
				}
			}
		}
	}

