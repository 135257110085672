
	.table-fluxo-dados {
		@apply w-full shadow-none rounded-t-[.25rem] border border-neutral-30 flex !border-b-0 overflow-hidden md2:overflow-x-auto;

		table {
			@apply w-full;
		}

		thead {
			@apply w-full;

			tr {
				
				th {
					@apply h-48 text-sm text-left font-semibold leading-100 text-neutral-80 !border-b !border-b-neutral-30 border-l-0 border-r-0 bg-neutral-20 relative px-8;

				
				}
			}

			.column-first {
				@apply w-[9.375rem];
			}
		}

		tbody {
			@apply bg-white text-neutral-80;

			.td-container {
				@apply text-sm font-normal leading-[120%] h-56 flex items-center;


			}

			tr {

				td {
					@apply border-b border-b-neutral-30;

				}

				.column-first {
					@apply bg-neutral-20;
				}



			}
		}
	}


