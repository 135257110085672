
	.texthelper {
		@apply text-neutral-80 tracking-tight font-medium text-xs;
	}

	.texthelper-icon {
		@apply flex items-center;

		svg {
			@apply w-20 h-20 text-neutral-75;
		}
	}

	.texthelper-error{
		@apply flex items-center;

		svg {
			@apply w-[1.125rem] h-[1.125rem] !text-alert-red-100;
		}
	}

	

