
	.flatpickr-day.selected {
		background: rgba(var(--primary-pure, 1));
		-webkit-box-shadow: none;
		box-shadow: none;
		color: #fff;
		border-color: rgba(var(--primary-pure, 1));

		&:hover {
			border-color: rgba(var(--primary-pure, 1));
			background: rgba(var(--primary-pure, 1));
		}
	}

	.flatpickr-months .flatpickr-prev-month:hover svg,
	.flatpickr-months .flatpickr-next-month:hover svg {
		fill: rgba(var(--primary-pure, 1));
	}
	.flatpickr-day.selected,
	.flatpickr-day.startRange,
	.flatpickr-day.endRange,
	.flatpickr-day.selected.inRange,
	.flatpickr-day.startRange.inRange,
	.flatpickr-day.endRange.inRange,
	.flatpickr-day.selected:focus,
	.flatpickr-day.startRange:focus,
	.flatpickr-day.endRange:focus,
	.flatpickr-day.selected:hover,
	.flatpickr-day.startRange:hover,
	.flatpickr-day.endRange:hover,
	.flatpickr-day.selected.prevMonthDay,
	.flatpickr-day.startRange.prevMonthDay,
	.flatpickr-day.endRange.prevMonthDay,
	.flatpickr-day.selected.nextMonthDay,
	.flatpickr-day.startRange.nextMonthDay,
	.flatpickr-day.endRange.nextMonthDay {
		@apply !bg-primary-pure !border-primary-pure;
	}

