
	.btn {
		@apply bg-primary-pure 
		rounded
		px-14 
		cursor-pointer 
		font-normal
		text-white 
		font-[.875rem] 
		transition 
		border-2 
		border-transparent 
		ring-transparent 
		shadow-transparent 
		h-40 
		inline-flex 
		items-center 
		leading-100
		gap-8
		shrink-0
		justify-center;

		&:hover {
			@apply  !bg-primary-light !text-white;
			--icon-color: #ffffff;
		}

		/* &:active {
			@apply !bg-primary-dark;
		} */

		/* Secundario */
		&.secondary {
			@apply !bg-white text-neutral-100 !border  !border-solid !border-neutral-30;

			&:hover {
				@apply !bg-primary-pure !text-white shadow-none;
			}
		}

		/* Terciario */
		&.tertiary {
			@apply !bg-transparent text-neutral-100;
			&:hover {
				@apply !bg-neutral-100/10 !text-neutral-100;
				--icon-color: #09121c;
			}
/* 
			&:active {
				@apply border border-solid border-neutral-40;
			} */

			&:disabled {
				@apply pointer-events-none !bg-transparent !border-none opacity-40;
			}
		}

		&.quartenary {
			@apply bg-transparent text-primary-pure;
			&:hover {
				@apply border-transparent bg-primary-pure-10-o;
			}
		}

		&.icon-left {
			svg {
				@apply w-[1rem] h-[1rem];
			}
			&:hover {
				@apply fill-current;
			}
		}

		&.icon-right {
			svg {
				@apply w-[1.5rem] h-[1.5rem];
			}
			&:hover {
				@apply fill-current;
			}
		}
		&.red {
			@apply bg-alert-red-10 text-alert-red-100 hover:border-alert-red-100 hover:!bg-alert-red-10;
		}

		&.disabled {
			@apply pointer-events-none
			   select-none 
			 bg-neutral-30 
			 text-neutral-60;
		}
	}
	.btn-notificacao {
		@apply text-neutral-70 h-48 w-48 rounded-full border-neutral-30 border grid place-items-center bg-white hover:bg-neutral-30 transition relative;
	}

	.btn-perfil {
		@apply text-neutral-70 h-48  rounded-[99px] border-neutral-30 border bg-white hover:bg-neutral-30 transition relative flex items-center;
		&.ative,
		&:hover {
			@apply !rounded-b-none rounded-t-[2rem];
		}
	}

	.btn-selecionado {
		@apply bg-[#1CC066] hover:border-[#1CC066] hover:text-[#1CC066];
	}
	.btn-action {
		@apply h-full flex gap-4 items-center font-medium px-16 py-[.375rem] rounded-[.25rem] justify-center;

		svg {
			@apply w-16 h-16;
		}

		&.update {
			@apply text-primary-pure;

			&:hover {
				@apply bg-primary-pure-10-o;
			}
		}

		&.delete {
			@apply text-alert-red-100;

			&:hover {
				@apply bg-alert-red-10-o;
			}
		}
	}

	.btn-table-hover {
		@apply text-primary-pure
		font-medium
		px-16 
		py-[.375rem]
		rounded-lg
		w-full
		h-full
		flex
		items-center
		cursor-pointer;

		&:hover {
			@apply bg-primary-pure-10-o;
		}

		&.action {
			@apply justify-center;
		}
	}
