
	.input-check-label {
		@apply bg-transparent text-neutral-75 font-normal text-base py-14 px-14 !rounded-[3px]  relative transition-colors duration-200 h-40 leading-150 ;

		~ svg {
			@apply text-neutral-70;
		}

		&.inverse {
			@apply bg-white;
		}

		&:hover {
			@apply border-neutral-50;
		}

		&.active {
			@apply border-neutral-60;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 0 transparent inset !important ;
			font-size: 1rem;

			/* Cor do texto do autocomplete */
			-webkit-text-fill-color: #343A40;
			font-family: "Be Vietnam Pro", sans-serif !important;
		}

		&:focus-within {
			@apply !border-primary-pure;

			~ svg {
				@apply !text-primary-pure;
			}
		}

		&::placeholder {
			@apply text-neutral-75 font-normal text-sm;
		}

		&.disabled {
			@apply pointer-events-none select-none bg-neutral-20;

			&::placeholder {
				@apply text-neutral-60;
			}
		}
	}

	.input-icon {
		@apply h-max relative;

		.input-check-label {
			@apply pr-40 w-full;
		}

		svg {
			@apply right-12 absolute top-0 bottom-0 my-auto w-20 h-20;
		}

		&.icon-left {
			.input-check-label {
				@apply pr-12 pl-40;
			}

			svg {
				@apply left-12 right-[initial] absolute top-0 bottom-0 my-auto w-20 h-20;
			}
		}
	}

	.input-readonly {
		@apply text-lg text-neutral-80 font-normal bg-transparent appearance-none pointer-events-none select-none;
	}

