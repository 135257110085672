
	.grid-template {
		@apply grid grid-cols-2 bg-neutral-10;

		.container-left {
			@apply bg-neutral-100 h-screen flex items-center md:hidden p-48 relative;

			svg {
				@apply object-contain w-[39.1875rem] h-[39.5rem] mx-auto;
			}
		}

		.container-right {
			@apply flex items-center justify-center h-screen bg-neutral-10 md:col-span-2;
		}
	}

