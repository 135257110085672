
	.display {
		@apply text-[64px] font-normal leading-[80px] tracking-tight;
	}

	.title-1 {
		@apply text-5xl leading-[60px] tracking-tight;
		/* svg {
			@apply w-24 h-24 shrink-0;
		} */
	}

	.title-2 {
		@apply text-[32px] font-normal leading-[44px] tracking-tight;
	}

	.title-3 {
		@apply text-2xl font-normal leading-[32px] tracking-tight;
	}

	.headline-1 {
		@apply text-lg font-medium leading-[26px] tracking-tight;
	}

	.headline-2 {
		@apply text-base font-medium leading-[26px] tracking-tight;
	}

	.headline-3 {
		@apply !text-xs font-medium leading-[24px] tracking-tight;
	}

	.headline-4 {
		@apply text-xs font-medium leading-[19px] tracking-tight;
	}

	.headline-5 {
		@apply text-[10px] font-medium leading-[18px] tracking-tight;
	}

	.paragraph-1 {
		@apply text-base leading-[24px] font-normal tracking-tight;
	}

	.paragraph-2 {
		@apply text-sm leading-[22px] font-normal tracking-tight;
	}

	.paragraph-3 {
		@apply text-xs leading-[20px] font-normal tracking-tight;
	}

	.paragraph-4 {
		@apply text-[10px] leading-[18px] font-normal tracking-tight;
	}

	.caps-1-semibold {
		@apply font-medium text-base leading-[20px] tracking-[6%];
	}

	.caps-1 {
		@apply font-normal text-sm leading-[22px] tracking-[4%];
	}

	.caps-2-semibold {
		@apply font-semibold text-xs leading-[16px] tracking-[4%];
	}

	.caps-2 {
		@apply font-normal text-xs leading-[16px] tracking-[4%];
	}

	.caps-3-semibold {
		@apply font-semibold text-[10px] leading-[16px] tracking-[1px];
	}

	.caps-3 {
		@apply font-normal text-[10px] leading-[16px] tracking-[1px];
	}

