
	[js-dropdown] {
		.dropdown-container {
			@apply absolute -right-1 min-w-[15rem] p-8 bg-white shadow-regular border border-neutral-30 opacity-0 pointer-events-none translate-x-12 transition w-full z-50 -mt-2 rounded-b-md;
		}
		.drop-button {
			@apply text-neutral-75 h-48 border-solid border-neutral-30 border bg-white hover:bg-neutral-30 transition relative flex items-center rounded-t-md font-normal;
		}

		&.active,
		&:hover {
			> button {
				@apply shadow-regular;
			}

			.dropdown-container {
				@apply opacity-100 pointer-events-auto translate-x-0;
			}
		}
		.dropdown-item {
			@apply rounded-sm cursor-pointer text-neutral-75 !text-[.875rem] font-normal hover:bg-neutral-20 transition block w-full shrink-0 py-8 px-12 text-left h-max;
		}
	}

