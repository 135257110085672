
	.textarea {
		@apply bg-white border-[.0625rem] border-neutral-30 pt-16 px-16 gap-8 rounded-t-md resize-none outline-none min-h-[7.5rem] placeholder-neutral-70 text-neutral-80 font-normal;
	
		&::placeholder {
			@apply text-neutral-75  font-normal  text-base;
		}

		&:hover {
			@apply border-neutral-50;
		}

		&:focus-within {
			@apply !border-primary-pure ;
		}
		
		&.active {
			@apply border-neutral-60;
		}
		&.inverse {
			@apply bg-neutral-10 border-t-0 border-x-0	;

		}
	}

	.textarea-icon {
		@apply w-max h-max relative;

		svg {
			@apply right-12 absolute top-0 bottom-0 my-20 w-20 h-20;
		}
		.textarea {
			&:not(:placeholder-shown) {
				~ svg {
					@apply hidden;
				}
			}
		}

		&.icon-left {
			.textarea {
				@apply pl-32;
			}
			svg {
				@apply left-12  absolute top-0 bottom-0 my-20  w-20 h-20;
			}
		}
	}

